import * as edulms from "./edulms";
import * as dynamic from "./dynamic";
import * as preloader from "./preloader";
import * as utils from "./utils";
import * as modals from "./modals";

export { init, startJob, stopJob, updateCounts };


interface NotificationResponseData {
    totalUnseen: number,
    countsPerClass: Record<number, number>,
    dropdownItems: string,
    alerts: string,
    modals: string,
}

let notificationJob: number | undefined;

/**
 * Listen for dismissal of notifications. It posts to backend asynchronously
 * and dismisses the notification immediately. The notification will then
 * be marked as dismissed (and seen) in the database.
 */
function initDismissListener(root: ParentNode) {
    root.querySelectorAll(".notification").forEach(notificationElem => {
        const dismissUrl = notificationElem instanceof HTMLElement
            ? notificationElem.dataset.notificationDismissUrl
            : null;

        if (dismissUrl) {
            notificationElem.querySelectorAll(".notification-dismiss").forEach(dismissElem => {
                dismissElem.addEventListener("click", () => {
                    utils.fetchWithCsrf(addURLParams(dismissUrl), "POST")
                        .then(response => response.json())
                        .then(data => handleResponseData(data));
                });
            });
        }
    });
}


/**
 * Listen for clicks on the new type of notifications. When one is clicked,
 * it will be marked as "seen" in the database. Then navigate to the
 * associated URL, if one is set.
 */
function initClickListener(root: ParentNode) {
    root.querySelectorAll(".notification").forEach(notificationElem => {
        if (notificationElem instanceof HTMLElement) {
            const linkElem = notificationElem.getElementsByClassName("notification-link").item(0);

            linkElem?.addEventListener("click", () => {
                const linkUrl = notificationElem.dataset.notificationUrl;
                const seenUrl = notificationElem.dataset.notificationSeenUrl;

                // If there is a "mark as seen" URL, and the notification
                // isn't already seen, request it first and then navigate to
                // "link" URL if it exists:
                if (!notificationElem.classList.contains("seen") && seenUrl) {
                    fetch(addURLParams(seenUrl), { method: "POST" })
                        .then(response => response.json())
                        .then(data => handleResponseData(data))
                        .finally(() => {
                            if (linkUrl) location.href = linkUrl;
                        });
                }
                // Otherwise, go straight to "link" URL:
                else if (linkUrl) location.href = linkUrl;
            });
        }
    });
}


/**
 * Listen for clicks on links with class="all-notifications-seen".
 */
function initAllSeenClickListener(root: ParentNode) {
    root.querySelectorAll("a.all-notifications-seen").forEach(e => e.addEventListener("click", event => {
        if (event.currentTarget instanceof HTMLAnchorElement) {
            event.preventDefault();
            utils.fetchWithCsrf(event.currentTarget.href, "POST")
                .then(response => response.json())
                .then(data => handleResponseData(data));
        }
    }));
}


/**
 * If the current URL matches the `data-notification-seen-url` of an unseen
 * notification, set that notification to 'seen'.
 */
function setSeenByCurrentUrl() {
    const currentUrl = new URL(window.location.href);

    document.querySelectorAll(".notification:not(.seen)").forEach(elem => {
        if (
            elem instanceof HTMLElement &&
            elem.dataset.notificationSeenUrl && (
                elem.dataset.notificationUrl == currentUrl.pathname ||
                elem.dataset.notificationUrl == currentUrl.pathname + currentUrl.search
            )
        ) {
            utils.fetchWithCsrf(addURLParams(elem.dataset.notificationSeenUrl), "POST")
                .then(response => response.json())
                .then(data => handleResponseData(data));
        }
    })
}


/**
 * Update DOM with new notification counts.
 */
function updateCounts(totalUnseen: number, countsPerClass: Record<number, number>) {
    // Update "main" count in top navbar, and also show the appropriate
    // bell icon:
    const dropdown = document.getElementById("notification-dropdown");
    const filledIcon = dropdown?.getElementsByClassName("filled-icon").item(0);
    const unfilledIcon = dropdown?.getElementsByClassName("unfilled-icon").item(0);
    const dropdownClassCounts: Record<number, number> = {};

    if (totalUnseen) {
        filledIcon?.classList.remove("d-none");
        unfilledIcon?.classList.add("d-none");
        document.querySelectorAll(".notification-count-unseen").forEach(elem => {
            elem.classList.remove("d-none");
            elem.textContent = totalUnseen.toString();
        });
    }
    else {
        filledIcon?.classList.add("d-none");
        unfilledIcon?.classList.remove("d-none");
        document.querySelectorAll(".notification-count-unseen").forEach(elem => {
            elem.classList.add("d-none");
        });
    }

    // Update class specific counts (in top navbar and sidebar):
    document.querySelectorAll(".notification-count-class").forEach(elem => {
        if (elem instanceof HTMLElement && elem.dataset.class != undefined) {
            const classId = parseInt(elem.dataset.class);
            if (classId in countsPerClass && countsPerClass[classId] > 0) {
                // Only add to "total class notifications" badge if this is
                // for a class listed in the "my classes" dropdown:
                if (elem.closest(".my-classes-dropdown") != null) {
                    dropdownClassCounts[classId] = countsPerClass[classId];
                }
                elem.textContent = countsPerClass[classId].toString();
                elem.classList.remove("d-none");
            }
            else elem.classList.add("d-none");
        }
    });

    // Update class specific total (in top navbar):
    let classesTotal = 0;
    for (const key in dropdownClassCounts) classesTotal += dropdownClassCounts[key];
    document.querySelectorAll(".notification-count-class-total").forEach(elem => {
        if (classesTotal == 0) elem.classList.add("d-none");
        else {
            elem.textContent = classesTotal.toString();
            elem.classList.remove("d-none");
        }
    });
}


function handleResponseData(data: NotificationResponseData) {
    const dropdownContent = document.getElementById("notification-dropdown-container");
    const alertContent = document.getElementById("notification-alert-container");
    const modalContent = document.getElementById("notification-modal-container");

    updateCounts(data.totalUnseen, data.countsPerClass || {});
    if (dropdownContent) {
        dynamic.injectHTML(data.dropdownItems, dropdownContent);
        preloader.hide(dropdownContent);
    }
    if (alertContent) dynamic.injectHTML(data.alerts, alertContent);
    if (modalContent) {
        // Destroy any existing notification modals _that are not currently
        // showing_, and append any new ones:
        modalContent.querySelectorAll(".modal:not(.show)").forEach(modal => modal.remove());
        dynamic.injectHTML(data.modals, modalContent, null, true);
        modals.enqueue(modalContent.querySelectorAll(".modal"));
    }
}


/**
 * This is run on init and then once every 60 seconds. However, all the URL:s
 * we POST to in order to mark notifications as dismissed or seen also return
 * complete notification data and will run handleResponseData(), so the
 * notifications are immediately updated.
 */
function fetchNotifications() {
    if (edulms.runtimeContext.urls.notifications) {
        fetch(addURLParams(edulms.runtimeContext.urls.notifications))
            .then(response => response.json())
            .then(data => utils.onDOMLoaded(() => handleResponseData(data)));
    }
}


function addURLParams(url: string): string {
    const newUrl = new URL(url, document.baseURI);

    newUrl.searchParams.set("view_name", encodeURIComponent(edulms.runtimeContext.viewName));
    if (edulms.runtimeContext.activeSchoolClassId)
        newUrl.searchParams.set("class_id", edulms.runtimeContext.activeSchoolClassId.toString());
    return newUrl.toString();
}


function startJob() {
    notificationJob = window.setInterval(fetchNotifications, 60000);
}


function stopJob() {
    window.clearInterval(notificationJob);
}


function init() {
    fetchNotifications();
    setSeenByCurrentUrl();

    dynamic.onDOMLoaded(root => {
        initClickListener(root);
        initDismissListener(root);
        initAllSeenClickListener(root);
    });

    startJob();
}
