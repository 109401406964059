import "../vendor/pygal.js/2.0.x/pygal-tooltips";

import * as dynamic from "./dynamic";
import * as utils from "./utils";

export { getOrCreateGraph, init };


declare global {
    const pygal: {
        init(ctx: Element): any;
    };
}


interface Box {
    x: number;
    y: number;
    height: number;
    width: number;
}


const cache: BaseAjaxGraph[] = [];


/**
 * Functionality common to all AJAX fetched graphs.
 */
class BaseAjaxGraph {
    readonly cache: Record<string, string> = {};  // URL -> HTML
    readonly container: HTMLElement;

    fetched = false;
    fetchInProgress = false;
    containerWidth = -1;
    resizeObserver?: ResizeObserver;
    resizeTimeoutID: number | null = null;

    constructor(container: HTMLElement) {
        this.container = container;
    }

    /**
     * @param container An element with class="ajax-graph", whose contents
     * will be replaced by result of AJAX fetch.
     */
    fetch(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.fetchInProgress) reject("Another fetch is in progress");
            if (!this.container.dataset.url) throw new Error("Graph URL is undefined");
            if (this.container.offsetWidth <= 0) reject("Container width is <= 0");

            this.containerWidth = this.container.offsetWidth;
            this.fetchInProgress = true;
            this.container.dispatchEvent(new Event("graphloadstart"));

            const params = new URLSearchParams({
                offsetWidth: this.container.offsetWidth.toString(),
                viewportWidth: document.documentElement.clientWidth.toString(),
                viewportHeight: document.documentElement.clientHeight.toString(),
                ...this.container.dataset
            });
            params.delete("url");
            const url = `${this.container.dataset.url}?${params.toString()}`;

            if (url in this.cache) {
                this.container.dispatchEvent(
                    new CustomEvent("graphloadfinish", { detail: { isEmpty: false } })
                );
                utils.setInnerHTML(this.container, this.cache[url]);
                this.fetchInProgress = false;
                resolve();
            }
            else fetch(url)
                .then(response => {
                    if (!response.ok) throw new Error(response.statusText);
                    return response.text();
                }).then(html => {
                    this.container.dispatchEvent(new CustomEvent("graphloadfinish", { detail: { isEmpty: !html } }));

                    if (!this.fetched) {
                        this.fetched = true;
                        if (this.container.dataset.loaded) this.container.dataset.loaded = "true";
                    }

                    if (html) {
                        this.cache[url] = html;
                        utils.setInnerHTML(this.container, html);
                        resolve();
                    }
                }).finally(() => {
                    this.fetchInProgress = false;
                });
        });
    }

    observeResize(observed?: Element | null) {
        if (!this.resizeObserver) {
            const callback = (entries: ResizeObserverEntry[]) => {
                if (this.resizeTimeoutID) window.clearTimeout(this.resizeTimeoutID);

                this.resizeTimeoutID = window.setTimeout(() => {
                    for (const entry of entries) {
                        const containerWidth = Math.round(entry.contentRect.width);
                        if (containerWidth > 0 && containerWidth != this.containerWidth) {
                            this.fetch();
                        }
                    }
                }, 100);
            }
            this.resizeObserver = new ResizeObserver(callback.bind(this));
            this.resizeObserver.observe(observed || this.container);
        }
    }
}


class AjaxGraph extends BaseAjaxGraph {
    fetch(): Promise<void> {
        return new Promise<void>((resolve, _) => {
            super.fetch().then(() => {
                const chart = this.container.getElementsByClassName("pygal-chart").item(0);
                if (chart) pygal.init(chart);
                this.observeResize();
                resolve();
            }).catch(error => {
                console.warn(error, this.container);
            });
        });
    }
}


class FulfillmentGraphBar {
    bar: SVGGraphicsElement;
    tooltipContents: Element;
    timeoutId: number | null = null;

    constructor(bar: SVGGraphicsElement, tooltipContents: Element) {
        this.bar = bar;
        this.tooltipContents = tooltipContents;
    }

    activate() {
        this.bar.classList.add("active");
    }

    deactivate() {
        this.bar.classList.remove("active");
    }

    select() {
        this.bar.classList.add("selected");
    }

    deselect() {
        this.bar.classList.remove("selected");
    }

    /**
     * Get bar's current coordinates and dimensions, relative to the graph
     * (and, hence, also to the inner .svg-container).
     * CTM: { a: width ratio, d: height ratio, e: x change, f: y change }
     */
    getBox(): Box {
        const bbox = this.bar.getBBox();
        const ctm = this.bar.getCTM() || { a: 0, d: 0, e: 0, f: 0 };
        return {
            x: (bbox.x * ctm.a) + ctm.e,
            y: (bbox.y * ctm.d) + ctm.f,
            height: bbox.height * ctm.d,
            width: bbox.width * ctm.a,
        };
    }
}


class FulfillmentGraphTooltip {
    outer: HTMLElement;
    inner: HTMLElement;
    tooltip: HTMLElement;

    margin = 10;
    mouseOverTooltip = false;
    bars: FulfillmentGraphBar[] = [];
    selectedBar: FulfillmentGraphBar | null = null;
    activeBar: FulfillmentGraphBar | null = null;
    tooltipTimeoutId: number | null = null;

    constructor(outer: HTMLElement, inner: HTMLElement, tooltip: HTMLElement, contentContainer: Element) {
        this.outer = outer;
        this.inner = inner;
        this.tooltip = tooltip;

        tooltip.addEventListener("mouseenter", () => { this.mouseOverTooltip = true; });
        tooltip.addEventListener("mouseleave", () => { this.mouseOverTooltip = false; });

        let idx = 0;
        inner.querySelectorAll(".bars .bar").forEach(svgbar => {
            if (svgbar instanceof SVGGraphicsElement) {
                this.bars.push(this.initBar(svgbar, contentContainer.children[idx++]));
            }
        });
    }

    initBar(elem: SVGGraphicsElement, tooltipContents: Element): FulfillmentGraphBar {
        const bar = new FulfillmentGraphBar(elem, tooltipContents);

        /**
         * If the pointer enters the bar and no other bar is in the "selected"
         * state: make this the active bar and show tooltip for it.
         */
        bar.bar.addEventListener("mouseenter", () => {
            if (!this.selectedBar || this.selectedBar === bar) {
                this.activate(bar);
                this.showTooltip(bar);
            }
        });

        /**
         * If the pointer leaves the bar and the bar is not in the "selected"
         * state (meaning it was selected by clicking, and no other bar has
         * been selected after it), do this:
         *   - Wait 1 sec
         *   - If the pointer is not over the tooltip:
         *     - Inactivate the bar
         *     - If no other bar if active: Hide the tooltip
         *   - Otherwise: Register similar "mouseleave" listener for tooltip
         */
        bar.bar.addEventListener("mouseleave", () => {
            if (this.selectedBar != bar) {
                bar.timeoutId = window.setTimeout(() => {
                    if (!this.mouseOverTooltip) {
                        this.deactivate(bar);
                        if (!this.activeBar) this.hideTooltip();
                    } else {
                        this.tooltip.addEventListener("mouseleave", () => {
                            this.tooltipTimeoutId = window.setTimeout(() => {
                                this.deactivate(bar);
                                if (!this.activeBar) this.hideTooltip();
                            }, 1000);
                        }, { once: true });
                    }
                }, 1000);
            }
        });

        /**
         * Clicking on a bar toggles its "selected" state.
         */
        bar.bar.addEventListener("click", () => {
            if (this.selectedBar != bar) {
                // This also deselect all other bars:
                this.select(bar);
                this.showTooltip(bar);
            } else {
                this.deselect(bar);
            }
        });

        return bar;
    }

    hideTooltip() {
        this.tooltip.style.visibility = "hidden";
    }

    activate(bar: FulfillmentGraphBar) {
        bar.activate();
        this.activeBar = bar;
        this.resetOthers(bar);
    }

    deactivate(bar: FulfillmentGraphBar) {
        bar.deactivate();
        if (this.activeBar === bar) this.activeBar = null;
    }

    select(bar: FulfillmentGraphBar) {
        bar.select();
        this.selectedBar = bar;
        this.resetOthers(bar);
    }

    deselect(bar: FulfillmentGraphBar) {
        bar.deselect();
        if (this.selectedBar === bar) this.selectedBar = null;
    }

    resetOthers(bar: FulfillmentGraphBar) {
        this.bars.forEach(otherBar => {
            if (otherBar != bar) {
                otherBar.deactivate();
                otherBar.deselect();
            }
        });
    }

    showTooltip(bar: FulfillmentGraphBar) {
        if (bar.timeoutId) clearTimeout(bar.timeoutId);
        if (this.tooltipTimeoutId) clearTimeout(this.tooltipTimeoutId);
        this.tooltip.innerHTML = bar.tooltipContents.innerHTML;
        this.tooltip.style.visibility = "visible";
        this.tooltip.style.left = this.getTooltipX(bar) + "px";
        this.tooltip.style.top = this.getTooltipY(bar) + "px";
        // Apparently, scrollbar-width only works in Firefox, hence ts-ignore
        if (this.tooltip.scrollHeight > this.tooltip.clientHeight) {
            // @ts-ignore
            this.tooltip.style.scrollbarWidth = "thin";
            this.tooltip.style.overflowY = "auto";
        }
        else {
            // @ts-ignore
            this.tooltip.style.scrollbarWidth = "none";
            this.tooltip.style.overflowY = "hidden";
        }
    }

    /**
     * Get X position of tooltip, were it to be attached to `bar` right now.
     *
     * We want the tooltip 10 px to the right of the bar. But if that means
     * the right edge of the toolbar will exceed the container's boundaries
     * (minus 10 px), place it to the left instead.
     */
    getTooltipX(bar: FulfillmentGraphBar): number {
        const box = this.getBox(bar);
        let left = box.x + box.width + this.margin;
        if (left + this.tooltip.offsetWidth > this.outer.offsetWidth) {
            // We're outside container
            left = box.x - this.tooltip.offsetWidth - this.margin;
        }
        return left;
    }

    /**
     * Get Y position of tooltip, were it to be attached to `bar` right now.
     *
     * We want the tooltip's lower edge 10 px above the lower edge of the
     * bar. But if that means the tooltip's upper edge would exceed the
     * container's boundaries (minus 10 px), lower the tooltip so it fits.
     *
     * If the tooltip is simply too high to fit inside the container, it
     * will be scrollable.
     */
    getTooltipY(bar: FulfillmentGraphBar): number {
        if (this.tooltip.offsetHeight >= this.outer.offsetHeight - (this.margin * 2)) return this.margin;
        const box = this.getBox(bar);
        const boxBottom = box.y + box.height;
        const top = boxBottom - this.tooltip.offsetHeight - this.margin;
        if (top < this.margin) return this.margin;
        return top;
    }

    /**
     * Get bar's current coordinates and dimensions, relative to our containing
     * element (i.e. this.outer).
     */
    getBox(bar: FulfillmentGraphBar): Box {
        const box = bar.getBox();
        box.x += this.inner.offsetLeft;
        box.y += this.inner.offsetTop;
        return box;
    }
}


class FulfillmentGraph extends BaseAjaxGraph {
    tooltip?: FulfillmentGraphTooltip;

    fetch(): Promise<void> {
        return new Promise<void>((resolve, _) => {
            super.fetch().then(() => {
                const inner = this.container?.getElementsByClassName("svg-container").item(0);
                const tooltip = this.container?.getElementsByClassName("graph-tooltip").item(0);
                const tooltipContents = this.container?.getElementsByClassName("graph-tooltip-contents").item(0);
                const outer = this.container.offsetParent;

                if (
                    outer instanceof HTMLElement &&
                    inner instanceof HTMLElement &&
                    tooltip instanceof HTMLElement &&
                    tooltipContents instanceof Element
                ) this.tooltip = new FulfillmentGraphTooltip(outer, inner, tooltip, tooltipContents);

                this.observeResize(outer);
                resolve();
            }).catch(error => {
                console.warn(error, this.container);
            });
        });
    }
}


function getOrCreateGraph(container: HTMLElement): BaseAjaxGraph {
    let graph = cache.find(entry => entry.container == container);
    if (graph) return graph;
    if (container.classList.contains("fulfillment-graph")) graph = new FulfillmentGraph(container);
    else graph = new AjaxGraph(container);
    cache.push(graph);
    return graph;
}


function init() {
    dynamic.onDOMLoaded(root => {
        root.querySelectorAll(".ajax-graph:not(.deferred)").forEach(container => {
            if (container instanceof HTMLElement) {
                getOrCreateGraph(container).fetch();
            }
        });
    });
}
