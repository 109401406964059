import "../../css/style.scss";

import { Modal } from "bootstrap";

import * as analytics from "../analytics";
import * as cookieConsent from "../cookieConsent";
import * as dynamic from "../dynamic";
import * as edulms from "../edulms";
import * as graphs from "../graphs";
import * as modals from "../modals";
import * as notifications from "../notifications";
import * as preloader from "../preloader";
import * as timeout from "../timeout";
import * as utils from "../utils";
import * as floatingTutorial from "../floatingTutorial";

export { BaseEntrypoint };


class BaseEntrypoint {
    readonly analytics = analytics;
    readonly dynamic = dynamic;
    readonly edulms = edulms;
    readonly floatingTutorial = floatingTutorial;
    readonly graphs = graphs;
    readonly modals = modals;
    readonly notifications = notifications;
    readonly preloader = preloader;
    readonly utils = utils;

    constructor() {
        const context = this.getRuntimeContext();

        console.log(
            "  __   __    __                __                     ___ _____\n" +
            " /  \\ |  \\  /  \\ \\       /    |  \\ |      /\\   |\\  | |      |\n" +
            "|  __ |__/ |    | \\  x  /     |__/ |     /__\\  | \\ | |--    |\n" +
            " \\__/ |  \\  \\__/   \\/ \\/      |    |___ /    \\ |  \\| |___   |\n" +
            "----------------------------------------------------------------\n" +
            `LMS version ${context.version}, build time: ${context.buildTime}`
        );

        // Init other modules
        edulms.init(context);
        notifications.init();
        dynamic.init();
        graphs.init();
        modals.init();
        preloader.init();
        analytics.init();
        floatingTutorial.init();
        cookieConsent.init();

        if (context.isAuthenticated && context.environment in ["production", "staging"]) timeout.init();
    }

    /**
     * We cannot set data-bs-dismiss attributes for elements in HelpArticle
     * texts, because CKEditor seems to filter those out for some reason.
     * Instead do:
     *   <a onclick="app.closeModal(this)">Close popup</a>
     */
    closeModal(target: HTMLElement) {
        const modalElem = target.closest(".modal");
        if (modalElem) Modal.getOrCreateInstance(modalElem).hide();
    }

    getRuntimeContext(): RuntimeContext {
        return JSON.parse(document.getElementById("js-runtime-context")?.textContent || "{}");
    }
}
