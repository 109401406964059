import * as cookieConsent from "./cookieConsent";
import * as dynamic from "./dynamic";
import * as edulms from "./edulms";
import * as utils from "./utils";


export {
    postEvent,
    init,
    removeAnalyticsIds,
}


interface EventExtraData {
    [key: string]: string | undefined;
}


let isNewSession = false;


function setRandomLocalId(): boolean {
    isNewSession = true;
    return setLocalId(crypto.randomUUID());
}


/**
 * Returns true if the stored value was changed. This also means session ID
 * was changed.
 */
function setLocalId(localId: string): boolean {
    let changed = false;
    if (localId != localStorage.getItem("analyticsLocalId")) {
        localStorage.setItem("analyticsLocalId", localId);
        // Local ID has changed, so force generation of new session ID (will
        // also set isNewSession to true):
        setRandomSessionId();
        changed = true;
    }
    utils.setCookie("analyticsLocalId", localId)
    return changed;
}


function setSessionId(sessionId: string) {
    if (sessionId != sessionStorage.getItem("analyticsSessionId")) {
        sessionStorage.setItem("analyticsSessionId", sessionId);
        isNewSession = true;
    }
    utils.setCookie("analyticsSessionId", sessionId);
}


function setSessionSource(source: string) {
    if (source != sessionStorage.getItem("analyticsSessionSource")) {
        sessionStorage.setItem("analyticsSessionSource", source);
        setRandomSessionId();
    }
    utils.setCookie("analyticsSessionSource", source);
}


function setRandomSessionId() {
    setSessionId(crypto.randomUUID());
}


function ensureCookies() {
    const sessionId = sessionStorage.getItem("analyticsSessionId");
    const localId = localStorage.getItem("analyticsLocalId");
    const source = sessionStorage.getItem("analyticsSessionSource");

    if (sessionId) utils.setCookie("analyticsSessionId", sessionId);
    if (localId) utils.setCookie("analyticsLocalId", localId);
    if (source) utils.setCookie("analyticsSessionSource", source);
}


function removeAnalyticsIds() {
    sessionStorage.removeItem("analyticsSessionId");
    localStorage.removeItem("analyticsLocalId");
    sessionStorage.removeItem("analyticsSessionSource");
    utils.deleteCookie("analyticsLocalId");
    utils.deleteCookie("analyticsSessionId");
    utils.deleteCookie("analyticsSessionSource");
}


function postEvent(eventType: string, extraData?: any) {
    const localId = localStorage.getItem("analyticsLocalId");
    const sessionId = sessionStorage.getItem("analyticsSessionId");
    const source = sessionStorage.getItem("analyticsSessionSource") || "organic";

    if (localId && sessionId && edulms.runtimeContext.urls.createAnalyticsEvent) {
        const formData = utils.createFormData({
            event_type: eventType,
            request_path: location.href,
            session_id: sessionId,
            local_id: localId,
            user_agent: navigator.userAgent,
            csrfmiddlewaretoken: utils.getCsrfToken(),
            referrer: document.referrer,
            source: source,
        });
        if (extraData) formData.set("extra_data", JSON.stringify(extraData));
        console.debug("Post analytics event:", formData);
        navigator.sendBeacon(edulms.runtimeContext.urls.createAnalyticsEvent, formData);
    }
}


/**
 * Extracts extra event data defined on `elem` via
 * `data-analytics-extra-[extra-attr-name]` attributes. The attribute name
 * will be camelCased.
 */
function getEventExtraData(elem: HTMLElement): EventExtraData {
    const extraData: EventExtraData = {};

    Object.keys(elem.dataset).forEach(key => {
        const extraKey = key.match(/^analyticsExtra(.+)$/)?.pop();
        const value = elem.dataset[key];
        // camelCase it:
        if (extraKey && value) extraData[extraKey] = `${value[0].toLowerCase()}${value.substring(1)}`;
    });

    return extraData;
}


function initAnalyticsEvents(root: Document | HTMLElement) {
    root.querySelectorAll("[data-analytics-on-click]").forEach(elem => {
        elem.addEventListener("click", onElementClick);
    });

    root.querySelectorAll("form[data-analytics-on-submit]").forEach(form => {
        form.addEventListener("submit", onFormSubmit);
    });

    // Links with data-analytics-on-click were covered above, so skip them now:
    root.querySelectorAll("a:not([data-analytics-on-click])").forEach(elem => {
        elem.addEventListener("click", onLinkClick);
    });
}


/**
 * For each element with a "data-analytics-on-click" attribute, post analytics
 * every time that element is clicked. The value of this data attribute will
 * be used as event type.
 *
 * Recipe for getting any element to post events just like an <a>:
 * <div data-analytics-on-click="clickInternalLink"
 *   data-analytics-extra-href="/foo/" data-analytics-extra-link-name="bar" />
 */
function onElementClick(event: MouseEvent) {
    if (event.target instanceof HTMLElement) {
        const eventType = event.target.dataset.analyticsOnClick?.trim()
        if (eventType) postEvent(eventType, getEventExtraData(event.target));
    }
}


/**
 * For each <form> element with a "data-analytics-on-submit" attribute,
 * post analytics when form is submitted. This data attribute's value will be
 * used as event type. If the attribute consists of several (space-separated)
 * values, post analytics events for all of them.
 *
 * Will always set extraData `formId` and `formAction`.
 */
function onFormSubmit(event: SubmitEvent) {
    const form = event.target;

    if (form instanceof HTMLFormElement) {
        form.dataset.analyticsOnSubmit?.trim().split(" ").forEach(eventType => {
            if (eventType) postEvent(
                eventType,
                {
                    formId: form.id,
                    formAction: form.action,
                    ...getEventExtraData(form)
                }
            );
        });
    }
}


/**
 * If an <a> element has a "href" or a "data-analytics-extra-name"
 * attribute, post event. Event type will be "clickExternalLink" if href
 * starts with "http" but not with location.origin, otherwise
 * "clickInternalLink".
 *
 * Event's extraData.href will be stripped of any GET params or hashes.
 *
 * NOTE: <a> elements with "data-analytics-on-click" attributes will not be
 * sent here at all, as they are taken care of in onElementClick().
 */
function onLinkClick(event: MouseEvent) {
    if (event.target instanceof HTMLAnchorElement && (event.target.href || event.target.dataset.analyticsExtraName)) {
        const eventType = event.target.href.startsWith("http") && !event.target.href.startsWith(location.origin)
            ? "clickExternalLink" : "clickInternalLink";

        postEvent(
            eventType,
            {
                href: event.target.href.replace(/([?#].*$)/, ""),
                ...getEventExtraData(event.target)
            }
        );
    }
}


/**
 * 1. Get existing local and session IDs, and session source, from storages,
 * or generate random IDs and set session source = "organic" if they do not
 * exist.
 *
 * 2. If a local ID is explicitly set in GET param, use that one and also
 * generate a new random session ID. This allows us to tie together events via
 * LMS links in emails, on growplanet.se, etc.
 *
 * 3. If a session source is set in GET params (as "a_s_s"), and differs from
 * the one in storage, update storage and also generate a new random session
 * ID.
 *
 * 4. If the GET params contain a campaign ID (as "a_s_c"), generate a new
 * random session ID and include the campaign ID in the sessionStart event
 * below.
 *
 * 5. If there was no session ID in storage, or the session ID has been
 * regenerated for any of the reasons above, consider this the start of a
 * new session and post a sessionStart event.
 *
 * 6. Send a loadPage event.
 *
 * 6. If GET param "a_e_t" (short for Analytics Event Type) is set,
 * immediately post that one.
 */
function init() {
    const currentURL = new URL(location.href);
    const localId = currentURL.searchParams.get("a_l_id");
    const eventType = currentURL.searchParams.get("a_e_t");
    const sessionSource = currentURL.searchParams.get("a_s_s");
    const sessionCampaign = currentURL.searchParams.get("a_s_c");

    if (!cookieConsent.isDenied()) {
        if (!sessionStorage.getItem("analyticsSessionId")) isNewSession = true;
        if (localId) setLocalId(localId);
        if (sessionSource) setSessionSource(sessionSource);
        if (!localStorage.getItem("analyticsLocalId")) setRandomLocalId();
        if (!sessionStorage.getItem("analyticsSessionId")) setRandomSessionId();
        if (sessionCampaign) setRandomSessionId();
        ensureCookies();

        if (isNewSession) {
            if (sessionCampaign) postEvent("sessionStart", { campaign: sessionCampaign });
            else postEvent("sessionStart");
        }

        if (eventType) postEvent(eventType);

        // Extra data for loadPage and data-analytics-on-load events:
        const extraData: EventExtraData = { viewName: edulms.runtimeContext.viewName };

        // Include any "data-analytics-extra-*" attributes:
        for (const key in document.body.dataset) {
            if (key.startsWith("analyticsExtra")) {
                extraData[key.replace(/^analyticsExtra(\w)(.*$)/, (_, p1, p2) => p1.toLowerCase() + p2)] = document.body.dataset[key];
            }
        }
        postEvent("loadPage", extraData);
        // If <body> has data-analytics-on-load attribute, send an event of
        // that type too:
        if (document.body.dataset.analyticsOnLoad) postEvent(document.body.dataset.analyticsOnLoad, extraData);

        // Hook up some event listeners:
        dynamic.onDOMLoaded(root => initAnalyticsEvents(root));
    }
    else {
        removeAnalyticsIds();
    }

    // Delete the GET params to make sure there are no duplicate posts.
    if (eventType || localId) {
        currentURL.searchParams.delete("a_l_id");
        currentURL.searchParams.delete("a_e_t");
        utils.replaceState({}, currentURL);
    }
}
