import * as edulms from "./edulms";
import * as utils from "./utils";
import * as notifications from "./notifications";
import * as analytics from "./analytics";

export { init };

interface SectionJson {
    paddingX: number;
    paddingY: number;
    position: number;
    postShowActions: string[];
    preShowActions: string[];
    selector: string | null;
    text: string;
    title: string;
}

interface FloatingTutorialJson {
    html: string;
    id: number;
    postShowActions: string[];
    preShowActions: string[];
    sections: SectionJson[];
}

interface Section extends SectionJson {
    element: Element | null;
    scrollingElement: Element | null;
}

function isFloatingTutorialJson(value: any): value is FloatingTutorialJson {
    return !!(
        value.sections instanceof Array &&
        value.preShowActions instanceof Array &&
        value.postShowActions instanceof Array &&
        typeof value.html == "string" &&
        typeof value.id == "number"
    );
}

class FloatingTutorialBackdrops {
    readonly top: HTMLElement;
    readonly right: HTMLElement;
    readonly bottom: HTMLElement;
    readonly left: HTMLElement;

    constructor(container: HTMLElement | null) {
        const top = container?.querySelector(".backdrop.top");
        const right = container?.querySelector(".backdrop.right");
        const bottom = container?.querySelector(".backdrop.bottom");
        const left = container?.querySelector(".backdrop.left");

        if (
            !(top instanceof HTMLElement) ||
            !(right instanceof HTMLElement) ||
            !(bottom instanceof HTMLElement) ||
            !(left instanceof HTMLElement)
        ) throw new Error();

        this.top = top;
        this.right = right;
        this.bottom = bottom;
        this.left = left;
    }

    hide() {
        this.top.style.opacity = "0";
        this.right.style.opacity = "0";
        this.bottom.style.opacity = "0";
        this.left.style.opacity = "0";
    }

    position(rect: DOMRect) {
        this.top.style.height = `${rect.top}px`;
        this.right.style.top = `${rect.top}px`;
        this.right.style.left = `${rect.right}px`;
        this.bottom.style.top = `${rect.bottom}px`;
        this.bottom.style.width = `${rect.right}px`;
        this.left.style.top = `${rect.top}px`;
        this.left.style.width = `${rect.left}px`;
        this.left.style.height = `${rect.height}px`;
    }
}


class FloatingTutorial {
    readonly arrow: HTMLElement;
    readonly backdrops: FloatingTutorialBackdrops;
    readonly container: HTMLElement;
    readonly frame: HTMLElement;
    readonly indicators: HTMLElement | undefined;
    readonly nextButton: HasDisabledAttr | undefined;
    readonly page: HTMLElement;
    readonly popup: HTMLElement;
    readonly postShowActions: string[];
    readonly previousButton: HasDisabledAttr | undefined;
    readonly sections: Section[];
    readonly text: HTMLElement;
    readonly title: HTMLElement;
    readonly tutorialSessionId: string;

    currentIndex: number = 0;
    tutorialId: number;

    constructor(tutorial: FloatingTutorialJson, sections: Section[]) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(tutorial.html, "text/html");
        const container = doc.getElementById("floating-tutorial");
        const frame = container?.querySelector(".frame");
        const arrow = container?.querySelector(".arrow");
        const popup = container?.querySelector(".popup");
        const title = popup?.querySelector(".title");
        const text = popup?.querySelector(".text");
        const nextButton = popup?.querySelector(".next");
        const previousButton = popup?.querySelector(".previous");
        const indicators = popup?.querySelector(".indicators");

        this.sections = sections;
        this.postShowActions = tutorial.postShowActions;
        this.tutorialId = tutorial.id;
        this.tutorialSessionId = crypto.randomUUID();

        if (
            !(container instanceof HTMLElement) ||
            !(frame instanceof HTMLElement) ||
            !(arrow instanceof HTMLElement) ||
            !(popup instanceof HTMLElement) ||
            !(title instanceof HTMLElement) ||
            !(text instanceof HTMLElement)
        ) throw new Error();

        this.page = document.getElementById("page") || document.body;
        this.backdrops = new FloatingTutorialBackdrops(container);
        this.container = container;
        this.frame = frame;
        this.arrow = arrow;
        this.popup = popup;
        this.title = title;
        this.text = text;

        this.container.addEventListener("keydown", keyevent => {
            if (
                keyevent instanceof KeyboardEvent &&
                (keyevent.key == "ArrowLeft" || keyevent.key == "ArrowRight" || keyevent.key == "Escape") &&
                (!keyevent.altKey && !keyevent.ctrlKey && !keyevent.metaKey && !keyevent.shiftKey)
            ) {
                console.log(keyevent);
                keyevent.preventDefault();
                keyevent.stopPropagation();
                if (keyevent.key == "ArrowLeft") this.previous();
                else if (keyevent.key == "ArrowRight") this.next();
                else if (keyevent.key == "Escape") this.hide();
            }
        });

        if (utils.hasDisabledAttr(nextButton)) {
            this.nextButton = nextButton;
            this.nextButton.addEventListener("click", () => { this.next() });
        }
        if (utils.hasDisabledAttr(previousButton)) {
            this.previousButton = previousButton;
            this.previousButton.addEventListener("click", () => { this.previous() });
        }
        if (indicators instanceof HTMLElement) {
            this.indicators = indicators;
        }
        this.popup.querySelector(".close")?.addEventListener("click", () => { this.hide() });
    }

    availableSpaceAbove(rect: DOMRect): number {
        // Height above the element in the current scroll state.
        return rect.top;
    }

    availableSpaceAboveAfterScroll(scrollingElement: Element, rect: DOMRect): number {
        const scrollingRect = scrollingElement.getBoundingClientRect();
        // Distance from container top to element top:
        const topOffset = rect.top - scrollingRect.top + scrollingElement.scrollTop;
        // Distance from container top to element bottom:
        const bottomOffset = rect.bottom - scrollingRect.top + scrollingElement.scrollTop;
        const maxTop = scrollingRect.bottom - rect.height;
        // Can we scroll this far?
        if (bottomOffset > scrollingElement.clientHeight) {
            return maxTop;
        } else {
            return scrollingRect.top + topOffset;
        }
    }

    availableSpaceBelow(rect: DOMRect): number {
        return window.innerHeight - rect.bottom;
    }

    availableSpaceBelowAfterScroll(scrollingElement: Element, rect: DOMRect): number {
        const scrollingRect = scrollingElement.getBoundingClientRect();
        // Max distance that can be scrolled:
        const maxScroll = scrollingElement.scrollHeight - scrollingElement.clientHeight
        // Distance from container top to element top:
        const topOffset = rect.top - scrollingRect.top + scrollingElement.scrollTop;

        return window.innerHeight - scrollingRect.top - Math.min(topOffset, maxScroll) - rect.height;
    }

    getElementRect(section: Section): DOMRect {
        if (section.element) {
            const elemRect = utils.getCombinedRect(section.element);
            return new DOMRect(
                elemRect.x - section.paddingX,
                elemRect.y - section.paddingY,
                Math.min(window.innerWidth, elemRect.width + (section.paddingX * 2)),
                Math.min(window.innerHeight, elemRect.height + (section.paddingY * 2))
            );
        }
        return new DOMRect(0, 0, 0, 0);
    }

    goto(index: number) {
        // Hide previous section and run postShowActions for it:
        if (index != this.currentIndex) {
            this.hideSection();
            doActions(this.sections[this.currentIndex].postShowActions);
            this.currentIndex = index;
        }

        this.showSection(this.sections[index]);
    }

    hide() {
        const actionSet = new Set(this.postShowActions.concat(this.sections[this.currentIndex].postShowActions));
        doActions(Array.from(actionSet));
        analytics.postEvent(
            "floatingTutorialFinish",
            {
                tutorialId: this.tutorialId,
                tutorialSessionId: this.tutorialSessionId,
            },
        )
        document.addEventListener(
            "transitionend",
            () => {
                utils.showVerticalScrollbar();
                if (this.container.parentElement != null) document.body.removeChild(this.container);
            },
            { once: true },
        );
        this.hideSection();
        this.backdrops.hide();
    }

    hideSection() {
        this.popup.style.opacity = "0";
        this.arrow.style.opacity = "0";
        this.frame.style.borderWidth = "0px";
        this.frame.style.backgroundColor = "rgba(0,0,0,.5)";
    }

    next() {
        if (this.currentIndex < this.sections.length - 1) this.goto(this.currentIndex + 1);
    }

    popupFits(rect: DOMRect): boolean {
        const popupHeight = this.popup.offsetHeight + 8;
        return (
            popupHeight <= this.availableSpaceAbove(rect) ||
            popupHeight <= this.availableSpaceBelow(rect)
        );
    }

    positionFrame(rect: DOMRect) {
        this.frame.style.top = `${rect.top}px`;
        this.frame.style.left = `${rect.left}px`;
        this.frame.style.height = `${rect.height}px`;
        this.frame.style.width = `${rect.width}px`;
        this.frame.style.backgroundColor = "rgba(0,0,0,0)";
        if (rect.width && rect.height)
            this.frame.style.borderWidth = "2px";
        else
            this.frame.style.borderWidth = "0px";
    }

    positionPopupAndArrow(rect: DOMRect) {
        const pageRect = this.page.getBoundingClientRect();
        const arrowRect = this.arrow.getBoundingClientRect();

        if (!!(rect.height && rect.width)) {
            // rect has a size, so use it:
            this.arrow.style.left = `${rect.left + (rect.width / 2) - (arrowRect.width / 2)}px`;

            if (!utils.windowReachesBreakpoint("sm")) {
                this.popup.style.left = `${window.innerWidth * 0.05}px`;
            }
            else {
                this.popup.style.left = `${rect.left - 1}px`;
                // Move popup left if it's too far to the right:
                if (rect.left + 410 > pageRect.right && pageRect.right > 410)
                    this.popup.style.left = `${pageRect.right - 410}px`;
            }

            if (this.availableSpaceAbove(rect) < this.availableSpaceBelow(rect)) {
                this.popup.style.bottom = "auto";
                this.popup.style.top = `${rect.bottom}px`;
                this.popup.classList.remove("above");

                this.arrow.style.bottom = "auto";
                this.arrow.style.top = `${rect.bottom}px`;
                this.arrow.classList.remove("above");
            } else {
                this.popup.style.bottom = `${window.innerHeight - rect.top}px`;
                this.popup.style.top = "auto";
                this.popup.classList.add("above");

                this.arrow.style.bottom = `${window.innerHeight - rect.top}px`;
                this.arrow.style.top = "auto";
                this.arrow.classList.add("above");
            }

            this.arrow.style.opacity = "1";
        }
        else {
            // rect has zero size, which means this is a section without an
            // associated element. Just place popup in center.
            const popupRect = this.popup.getBoundingClientRect();
            this.popup.style.left = `${(window.innerWidth / 2) - (popupRect.width / 2)}px`;
            this.popup.style.top = `${(window.innerHeight / 2) - (popupRect.height / 2)}px`;
        }

        this.popup.style.opacity = "1";
    }

    previous() {
        if (this.currentIndex > 0) this.goto(this.currentIndex - 1);
    }

    renderIndicators(index: number) {
        if (this.indicators) {
            this.indicators.innerHTML = "";
            for (let i = 0; i < this.sections.length; i++) {
                const indicator = document.createElement("div");
                indicator.classList.add("bg-primary", "rounded-3");
                if (i == index) {
                    indicator.style.flexGrow = "2";
                } else {
                    indicator.classList.add("opacity-50", "cursor-pointer");
                    indicator.style.flexGrow = "1";
                    indicator.addEventListener("click", () => { this.goto(i) });
                }
                this.indicators.append(indicator);
            }
        }
    }

    scrollIfNeeded(section: Section, onScrollEnd: () => void) {
        const element = section.element;
        const scrollingElement = section.scrollingElement;

        if (element && scrollingElement) {
            utils.scrollIntoView(element, () => {
                const rect = this.getElementRect(section);

                if (!this.popupFits(rect)) {
                    // In current scroll state, popup neither fits above nor
                    // below element; scroll scrolling container in the direction
                    // where it can be scrolled the most, as far as possible
                    // without hiding element.
                    const scrollingRect = scrollingElement.getBoundingClientRect();
                    let scrollBy = 0;

                    if (this.availableSpaceAboveAfterScroll(scrollingElement, rect) >= this.popup.offsetHeight + 8) {
                        scrollBy = rect.bottom - scrollingRect.bottom + 8;
                    }
                    else {
                        scrollBy = rect.top - scrollingRect.top - 8;
                    }
                    if (Math.abs(scrollBy) > 1) {
                        utils.addScrollEndListener(scrollingElement, onScrollEnd);
                        scrollingElement.scrollBy({top: scrollBy, behavior: "smooth"});
                        return;
                    }
                }
                onScrollEnd();
            });
        }
        else onScrollEnd();
    }

    showSection(section: Section) {
        const rect = this.getElementRect(section);

        doActions(section.preShowActions, () => {
            this.scrollIfNeeded(section, () => {
                this.title.innerHTML = section.title;
                this.text.innerHTML = section.text;

                this.backdrops.position(rect);
                this.positionFrame(rect);
                this.positionPopupAndArrow(rect);

                this.renderIndicators(this.currentIndex);
                utils.hideVerticalScrollbar();
                analytics.postEvent(
                    "floatingTutorialSectionShow",
                    {
                        tutorialId: this.tutorialId,
                        tutorialSessionId: this.tutorialSessionId,
                        sectionPosition: section.position,
                    },
                )

                if (this.previousButton) {
                    if (this.currentIndex == 0) this.previousButton.disabled = true;
                    else this.previousButton.disabled = false;
                }
                if (this.nextButton) {
                    if (this.currentIndex == this.sections.length - 1) this.nextButton.disabled = true;
                    else this.nextButton.disabled = false;
                }
            });
        });
    }

    start() {
        this.currentIndex = 0;
        document.body.append(this.container);
        analytics.postEvent(
            "floatingTutorialStart",
            { tutorialId: this.tutorialId, tutorialSessionId: this.tutorialSessionId },
        )
        this.goto(this.currentIndex);
    }
}


function doActions(actions: string[], onFinished?: () => void) {
    let actionsFinished = 0;
    const actionFinished = () => {
        if (++actionsFinished >= actions.length) onFinished?.apply(null);
    };

    if (actions.length == 0) onFinished?.apply(null);

    actions.forEach(action => {
        if (action == "EXPAND_SIDEBAR") edulms.showSidebar(actionFinished);
        else if (action == "CLICK_CLASS_PROGRESS_TAB") {
            document.getElementById("ft-class-progress-tab")?.click();
            actionFinished();
        }
        else if (action == "CLICK_PUPILS_TAB") {
            document.getElementById("ft-pupils-tab")?.click();
            actionFinished();
        }
        else if (action == "CLICK_CLASS_MANAGEMENT_TAB") {
            document.getElementById("ft-class-management-tab")?.click();
            actionFinished();
        }
        else if (action == "ADD_FAKE_NOTIFICATION") {
            notifications.stopJob();
            notifications.updateCounts(1, {});
            actionFinished();
        }
        else if (action == "REMOVE_FAKE_NOTIFICATION") {
            notifications.startJob();
            notifications.updateCounts(0, {});
            actionFinished();
        }
        else if (action == "EXPAND_MY_CLASSES_DROPDOWN")
            edulms.setDropdownShown("#navbar-classes-dropdown-menu", true, actionFinished);
        else if (action == "COLLAPSE_MY_CLASSES_DROPDOWN")
            edulms.setDropdownShown("#navbar-classes-dropdown-menu", false, actionFinished);
        else if (action == "EXPAND_HELP_DROPDOWN")
            edulms.setDropdownShown("#navbar-help-dropdown-menu", true, actionFinished);
        else if (action == "COLLAPSE_HELP_DROPDOWN")
            edulms.setDropdownShown("#navbar-help-dropdown-menu", false, actionFinished);
        else if (action == "EXPAND_MOBILE_MENU") edulms.setMobileMenuOpen(true, actionFinished);
        else if (action == "COLLAPSE_MOBILE_MENU") edulms.setMobileMenuOpen(false, actionFinished);
        else actionFinished();
    });
}


function init() {
    // runtimeContext.floatingTutorialUrl will only be set if we are actually
    // supposed to show a floating tutorial to this user, and then the URL
    // will only return a non-empty object if the user is currently on the
    // right page for this tutorial.
    if (edulms.runtimeContext.floatingTutorialUrl != null) {
        fetch(edulms.runtimeContext.floatingTutorialUrl)
            .then(response => response.json())
            .then(data => {
                if (isFloatingTutorialJson(data)) {
                    doActions(data.preShowActions, () => {
                        const sections: Section[] = [];

                        data.sections.forEach((s: SectionJson) => {
                            const element = (
                                s.selector == null ? null :
                                Array.from(document.querySelectorAll(s.selector))
                                    .filter(e => e.clientHeight > 0 && e.clientWidth > 0)[0]
                            );
                            const scrollingElement =
                                element instanceof Element ? utils.getVerticalScrollingContainer(element) : null;

                            if (element instanceof Element || s.selector == null)
                                sections.push({element: element, scrollingElement: scrollingElement, ...s});
                        });

                        if (sections.length > 0) new FloatingTutorial(data, sections).start();
                    });
                }
            });
    }
}
