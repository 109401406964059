import { Carousel, Modal } from "bootstrap";
import playerjs from "player.js";

import * as dynamic from "./dynamic";
import * as preloader from "./preloader";
import * as utils from "./utils";

export { init, enqueue, openNow };


let current: HTMLElement | null = null;
const queue: HTMLElement[] = [];


function isModalEvent(event: any): event is Modal.Event {
    return event instanceof Event && event.target instanceof HTMLElement;
}


/**
 * Show a modal on load if all of these are true:
 *
 * 1. `modalId` is the ID of an existing element with class="modal"
 * 2. If modal element has a "data-required-params" attribute (space separated
 *    list), GET also contains all those params
 *
 * Modal element may also have a "data-optional-params" attribute, which we
 * only use here for removing those params from URL after modal close.
 *
 * Also: If a visible element with data-bs-target set to this ID is found,
 * scroll to that element.
 */
function prepareOpenOnLoad(modalId: string): HTMLElement | undefined {
    const requiredParams: string[] = [];
    const optionalParams: string[] = [];
    const allParams = ["modal"];
    const currentURL = new URL(window.location.href);
    const modal = document.getElementById(modalId);

    const deleteUrlParams = () => {
        for (const param of allParams) {
            currentURL.searchParams.delete(param);
        }
        utils.replaceState({}, currentURL);
    };

    if (!modal || !modal.classList.contains("modal")) {
        deleteUrlParams();
        return;
    }

    if (modal.dataset.requiredParams && modal.dataset.requiredParams.trim()) {
        requiredParams.push(...modal.dataset.requiredParams.trim().split(/ +/));
        allParams.push(...requiredParams);
    }
    if (modal.dataset.optionalParams && modal.dataset.optionalParams.trim()) {
        optionalParams.push(...modal.dataset.optionalParams.trim().split(/ +/));
        allParams.push(...optionalParams);
    }

    for (const key of requiredParams) {
        const urlParam = currentURL.searchParams.get(key);
        if (!urlParam) {
            deleteUrlParams();
            return;
        }
        else modal.dataset[utils.toCamelCase(key)] = urlParam;
    }

    for (const key of optionalParams) {
        const urlParam = currentURL.searchParams.get(key);
        if (urlParam) modal.dataset[utils.toCamelCase(key)] = urlParam;
    }

    modal.addEventListener("hide.bs.modal", deleteUrlParams);

    // Try scrolling to modal toggling element
    utils.onPageLoaded(() => {
        const toggler = document.querySelector(`[data-bs-target="#${modalId}"]`);
        if (toggler instanceof HTMLElement && (toggler.offsetHeight || toggler.offsetWidth))
            utils.scrollToElement(toggler);
    });

    return modal;
}


/**
 * For Bootstrap carousel inside modals, we let the .modal element listen to
 * left/right button presses, because it's very flimsy letting .carousel do
 * that (seems like it only works if the .carousel element currently has
 * focus, i.e. has been clicked on).
 */
function initCarousel(modal: HTMLElement, carouselElem: HTMLElement) {
    modal.addEventListener("keydown", keyevent => {
        if (
            keyevent instanceof KeyboardEvent &&
            (keyevent.key == "ArrowLeft" || keyevent.key == "ArrowRight") &&
            (!keyevent.altKey && !keyevent.ctrlKey && !keyevent.metaKey && !keyevent.shiftKey)
        ) {
            const carousel = Carousel.getOrCreateInstance(carouselElem);

            keyevent.preventDefault();
            keyevent.stopPropagation();
            if (keyevent.key == "ArrowLeft") {
                carousel.prev();
                const arrow = carouselElem.querySelector(".carousel-item.active .carousel-control-prev:not(:hover)");
                if (arrow) arrow.animate([{opacity: 0.9}, {opacity: 0.5}], 150);
            }
            else if (keyevent.key == "ArrowRight") {
                carousel.next();
                const arrow = carouselElem.querySelector(".carousel-item.active .carousel-control-next:not(:hover)");
                if (arrow) arrow.animate([{opacity: 0.9}, {opacity: 0.5}], 150);
            }
        }
    });
}


/**
 * Initialize carousel with correct item when modal opened.
 * Will first try to get active item ID by checking for "data-active-item-id"
 * attribute on the element initiating the event. If that is not available,
 * checks for "data-active-item-id" attribute on the modal itself. If that
 * also fails, just start on the first item.
 */
function showCarousel(event: Modal.Event) {
    const modal = event.target;
    const toggler = event.relatedTarget;
    let activeItemId: string | undefined = undefined;

    if (toggler instanceof HTMLElement && toggler.dataset.activeItemId) activeItemId = toggler.dataset.activeItemId;
    else if (modal instanceof HTMLElement && modal.dataset.activeItemId) activeItemId = modal.dataset.activeItemId;

    const activeItemSelector = activeItemId ? `.carousel-item[data-item-id="${activeItemId}"]` : ".carousel-item";
    const activeItem = modal.querySelector(activeItemSelector);

    // Set class "active" on carousel item with data-item-id=`itemId`, thereby
    // opening it. Also make sure this is the ONLY active item.
    modal.querySelectorAll(".carousel-item")?.forEach(item => {
        if (item == activeItem) item.classList.add("active");
        else item.classList.remove("active");
    });
}


function onHidden(event: Modal.Event) {
    const nextModal = queue.shift();

    current = null;
    event.target.classList.remove("fade");
    // Open next modal in queue, if any:
    if (nextModal) {
        nextModal.classList.add("fade");
        Modal.getOrCreateInstance(nextModal).show();
    }
}


function onHide(event: Modal.Event) {
    utils.adjustHeader(false);
    // Pause video:
    event.target.querySelectorAll("video").forEach(elem => elem.pause());
    // Player.js constructor freaks out on iframes without src attribute:
    event.target.querySelectorAll("iframe[src]").forEach(elem => {
        (new playerjs.Player(elem)).pause();
    });
    // Hide any preloader:
    preloader.hide(event.target);
}


/**
 * If no modal is currently open, just go ahead with opening this one.
 * Otherwise, prevent the default action (i.e. opening the modal) and instead
 * place it last in the queue, to be opened as soon as the previous modal
 * closes.
 */
function onShow(event: Modal.Event) {
    const modal = event.target;

    if (current) {
        // Another modal is showing; enqueue this one.
        event.preventDefault();
        current.classList.add("fade");
        queue.push(modal);
    }
    else {
        utils.adjustHeader(true);
        current = modal;
        dynamic.onElementLoaded(modal, isDynamic => {
            // Autoplay video, regrettably:
            modal.querySelectorAll("video").forEach(video => video.play());
            // Player.js constructor freaks out on iframes without src attribute:
            modal.querySelectorAll("iframe[src]").forEach(elem => {
                const player = new playerjs.Player(elem);
                player.setCurrentTime(0);
                player.play();
            });
            // Clear alerts section, but only if content is not dynamic (because then
            // we assume we have just rendered exactly the stuff we want to show):
            if (!isDynamic) {
                const alerts = modal.getElementsByClassName("alerts").item(0);
                if (alerts) alerts.innerHTML = "";
            }
            if (modal.classList.contains("carousel-modal") && isModalEvent(event)) showCarousel(event);
        });
    }
}


function onShown(event: Modal.Event) {
    const modal = event.target;

    dynamic.onElementLoaded(modal, () => {
        // Set max-height for any dropdowns that should have it:
        utils.setDropdownMaxHeights(modal);
    });
}


function enqueue(elements: Element[] | NodeListOf<Element>) {
    const modals: HTMLElement[] = [];

    elements.forEach(modal => {
        if (modal instanceof HTMLElement) modals.push(modal);
    });

    modals
        .sort((a, b) => parseInt(b.dataset.priority || "50") - parseInt(a.dataset.priority || "50"))
        .forEach(modal => Modal.getOrCreateInstance(modal).show());
}


/**
 * Open `modal` immediately. If another modal (`current`) is open: put `modal`
 * first in the queue and `current` after, then close `current`. This should
 * open `modal` and also go back to `current` after that one closes.
 */
function openNow(modal: HTMLElement) {
    if (current) {
        current.classList.add("fade");
        modal.classList.add("fade");
        // Will place `modal` at pos 0 and `current` at pos 1 in `queue`:
        queue.unshift(current);
        queue.unshift(modal);
        Modal.getInstance(current)?.hide();
    }
    else Modal.getOrCreateInstance(modal).show();
}


/**
 * Factors that make a modal show up immediately on page load:
 *
 * 1. It has CSS class "show-on-load", OR:
 * 2. The URL has its ID in the "modal" parameter, along with other parameters
 *    it may require
 *
 * If there are multiple such modals, we open them sequentially, ordered
 * (descending) by the "data-priority" attribute on the .modal (if exists).
 * It is not very polite to subject the user to several modals after one
 * another, so use this extremely conservatively.
 */
function openModalsOnLoad() {
    utils.onDOMLoaded(() => {
        const onLoadModals = Array.from(document.getElementsByClassName("modal show-on-load"));
        const modalParam = (new URL(window.location.href)).searchParams.get("modal");

        if (modalParam) {
            // prepareOpenOnLoad() only returns an element if it is actually
            // ready to be opened:
            const modal = prepareOpenOnLoad(modalParam);
            if (modal) onLoadModals.push(modal);
        }
        enqueue(onLoadModals);
    });
}


function initAll() {
    // Traverse document and later any dynamically added content:
    dynamic.onDOMLoaded(root => {
        root.querySelectorAll(".modal").forEach(modal => {
            if (!(modal instanceof HTMLElement)) return;

            dynamic.onElementLoaded(modal, () => {
                // Init carousel inside modal, if such exists:
                if (modal.classList.contains("carousel-modal")) {
                    const carousel = modal.getElementsByClassName("carousel").item(0);
                    if (carousel instanceof HTMLElement) initCarousel(modal, carousel);
                }
                // Show preloader (if one exists) on modal form submit (it
                // will be hidden again in onModalHide):
                modal.querySelectorAll("form").forEach(form => {
                    form.addEventListener("submit", () => preloader.show(modal));
                });
            });

            // Do on modal show:
            modal.addEventListener("show.bs.modal", onShow);
            // Same, but for "shown" (i.e. DOM is fully loaded):
            modal.addEventListener("shown.bs.modal", onShown);
            modal.addEventListener("hide.bs.modal", onHide);
            modal.addEventListener("hidden.bs.modal", onHidden);
        });
    });
}


function init() {
    initAll();
    openModalsOnLoad();
}
