import * as analytics from "./analytics";
import * as utils from "./utils";


export { init, isDenied };

const COOKIE_NAME = "cookieconsent_status";


function hideBanner() {
    document.getElementById("cookie-consent-container")?.classList.remove("show");
}


function showBanner() {
    document.getElementById("cookie-consent-container")?.classList.add("show");
}


function allow() {
    utils.setCookie(COOKIE_NAME, "allow");
    hideBanner();
}


function deny() {
    utils.setCookie(COOKIE_NAME, "deny");
    analytics.removeAnalyticsIds();
    hideBanner();
}


function revoke() {
    utils.deleteCookie(COOKIE_NAME);
    showBanner();
}


function isDenied(): boolean {
    return utils.getCookie(COOKIE_NAME) == "deny";
}


function init() {
    const allowLink = document.getElementById("cookie-consent-allow");
    const denyLink = document.getElementById("cookie-consent-deny");
    const revokeLink = document.getElementById("cookie-consent-revoke");

    if (utils.getCookie(COOKIE_NAME) == undefined) showBanner();
    allowLink?.addEventListener("click", () => { allow() });
    denyLink?.addEventListener("click", () => { deny() });
    revokeLink?.addEventListener("click", () => { revoke() });
}
