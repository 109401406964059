import * as edulms from "./edulms";
import * as modals from "./modals";

export { init };


const timeoutValueElem = document.getElementById("timeout-value");

let pingLock = false;
let countdownID: number | null = null;
let pingEnqueued = false;


function updateTimeoutValue(secondsLeft: number) {
    if (timeoutValueElem) {
        let count: number;
        let fmt: string;

        if (secondsLeft >= 60) {
            count = Math.ceil(secondsLeft / 60);
            fmt = ngettext("less than %s minute", "less than %s minutes", count);
        }
        else {
            count = secondsLeft;
            fmt = ngettext("%s second", "%s seconds", count);
        }

        timeoutValueElem.textContent = interpolate(fmt, [count]);
    }
}


function cancelCountdown() {
    if (countdownID) window.clearInterval(countdownID);
    countdownID = null;
}


/**
 * Starts counting down from `seconds` seconds. For each second, update the
 * value in `timeoutValueElem`. When 0 is passed, cancel countdown and reload
 * the page. User should now be logged out in backend.
 */
function startCountdown(seconds: number) {
    let secondsLeft = seconds;

    // If another countdown is active, cancel it
    cancelCountdown();

    countdownID = window.setInterval(() => {
        if (secondsLeft < 0) {
            cancelCountdown();
            window.location.reload();
        } else {
            updateTimeoutValue(secondsLeft);
        }
        secondsLeft--;
    }, 1000);
}


/**
 * Sends a request to /ping/ on the server, resetting inactivity timeout.
 * Cancels any ongoing countdown.
 * Also blocks ping requests for 10 seconds, so we won't get spammed by them.
 * If called while this block is in effect, it will enqueue the ping and send
 * it immediately when the block is lifted.
 */
function ping(force=false) {
    if (edulms.runtimeContext.urls.ping) {
        if (!pingLock || force) {
            pingLock = true;
            cancelCountdown();
            fetch(edulms.runtimeContext.urls.ping);
            window.setTimeout(() => {
                pingLock = false;
                if (pingEnqueued) {
                    pingEnqueued = false;
                    ping();
                }
            }, 10000);
        } else pingEnqueued = true;
    }
}


/**
 * Check the pollTimeout endpoint for how how long it is left until inactivity
 * timeout. If it's 5 minutes or less, display warning and start countdown.
 */
function pollTimeout(displayWarningCallback: (secondsLeft: number) => void) {
    if (edulms.runtimeContext.urls.pollTimeout) {
        fetch(edulms.runtimeContext.urls.pollTimeout).then(response => response.json()).then(data => {
            const secondsLeft = data.secondsLeft;
            if (typeof secondsLeft === "number" && secondsLeft <= 5 * 60) {
                startCountdown(secondsLeft);
                displayWarningCallback(secondsLeft);
            }
        });
    }
}


/**
 * Run pollTimeout() immediately and then every 60 seconds. Also bind some
 * UI events to ping() so timeout will be restarted by them.
 */
function initializeTimeoutPoll(displayWarningCallback: (secondsLeft: number) => void) {
    // Every 60 seconds, check how long until inactivity timeout
    window.setInterval(() => pollTimeout(displayWarningCallback), 60000);
    // Do a first check immediately for good measure
    pollTimeout(displayWarningCallback);

    // On keyboard press, mouse click or window scroll, reset timeout
    window.addEventListener("scroll", () => ping());
    document.addEventListener("keyup", () => ping());
    document.addEventListener("click", () => ping());
}


function init() {
    const warningModal = document.getElementById("timeout-warning-modal");

    initializeTimeoutPoll(secondsLeft => {
        updateTimeoutValue(secondsLeft);
        if (warningModal) modals.openNow(warningModal);
    });
    document.getElementById("timeout-button")?.addEventListener("click", () => ping(true));
}
